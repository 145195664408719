// @flow
import * as React from 'react';

import Link from './Link';

function Advertisers() {
  return (
    <li className="nav-item">
      <Link to="/advertisers" className="nav-link">
        Advertisers
      </Link>
    </li>
  );
}

function Affiliates() {
  return (
    <li className="nav-item">
      <Link to="/affiliates" className="nav-link">
        Affiliates
      </Link>
    </li>
  );
}

function Apps() {
  const link = 'https://next.vortexads.io/apps';

  return (
    <li className="nav-item">
      <a href={link} className="nav-link">
        Apps
      </a>
    </li>
  );
}

const Billing = {
  Affiliate: function () {
    const handleClick = (event: SyntheticEvent<HTMLLinkElement>) => {
      event.preventDefault();
    };

    return (
      <li className="nav-item dropdown">
        <Link to="/billing" className="nav-link" onClick={handleClick}>
          Billing
        </Link>
        <div className="dropdown-content">
          <Link to="/billing/invoices">Invoices</Link>
          <Link to="/billing/payment-methods">Payment methods</Link>
        </div>
      </li>
    );
  },
  Manager: function () {
    return (
      <li className="nav-item">
        <Link to="/billing" className="nav-link">
          Billing
        </Link>
      </li>
    );
  },
};

function ControlPanel() {
  const handleClick = (event: SyntheticEvent<HTMLLinkElement>) => {
    event.preventDefault();
  };

  return (
    <li className="nav-item dropdown">
      <Link to="/control-panel" className="nav-link" onClick={handleClick}>
        Control panel
      </Link>
      <div className="dropdown-content">
        <Link to="/control-panel/users">Users</Link>
        <Link to="/control-panel/configuration">Configuration</Link>
        <Link to="/control-panel/schedulers">Schedulers</Link>
      </div>
    </li>
  );
}

function Dashboard() {
  return (
    <li className="nav-item">
      <Link to="/dashboard" className="nav-link">
        Dashboard
      </Link>
    </li>
  );
}

function Offers() {
  return (
    <li className="nav-item">
      <Link to="/offers" className="nav-link">
        Offers
      </Link>
    </li>
  );
}

function Postbacks() {
  return (
    <li className="nav-item">
      <Link to="/postbacks" className="nav-link">
        Postbacks
      </Link>
    </li>
  );
}

function Statistics() {
  const handleClick = (event: SyntheticEvent<HTMLLinkElement>) => {
    event.preventDefault();
  };

  return (
    <li className="nav-item dropdown">
      <Link to="/statistics" className="nav-link" onClick={handleClick}>
        Statistics
      </Link>
      <div className="dropdown-content">
        <Link to="/statistics/reports">Reports</Link>
        <Link to="/statistics/clicks">Clicks</Link>
      </div>
    </li>
  );
}

function SmartLink() {
  const link = 'https://smartlink.vortexads.io';

  return (
    <li className="nav-item">
      <a href={link} className="nav-link" target="_blank" rel="noopener noreferrer">
        SmartLink
      </a>
    </li>
  );
}

export default {
  Affiliate() {
    return (
      <React.Fragment>
        <Dashboard />
        <Offers />
        <Statistics />
        <Postbacks />
        <Billing.Affiliate />
        <SmartLink />
      </React.Fragment>
    );
  },
  Manager() {
    return (
      <React.Fragment>
        <Dashboard />
        <Offers />
        <Affiliates />
        <Advertisers />
        <Apps />
        <Statistics />
        <ControlPanel />
        <Billing.Manager />
        <SmartLink />
      </React.Fragment>
    );
  },
};
